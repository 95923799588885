var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BaseDropDown',_vm._g(_vm._b({ref:"list",class:[
    {
      'v-select_x-small': _vm.xSmall,
    },
  ],attrs:{"type":_vm.type,"value":_vm.currentGroup,"loading":_vm.loadingCardGroupsGetter || _vm.loading,"disabled":_vm.loadingCardGroupsGetter,"items":_vm.cardGroups,"menu-props":{
    maxHeight: _vm.xSmall ? 140 : 220,
    maxWidth: _vm.menuMaxWidth,
    contentClass: _vm.xSmall ? 'v-select__content_x-small' : undefined,
  },"rules":[_vm.fieldRules.required, _vm.fieldRules.maxCharsGroupName],"counter":_vm.counter,"dense":"","outlined":"","item-text":"name","item-value":"id"},on:{"change":_vm.onChangeCardGroup}},'BaseDropDown',_vm.$attrs,false),_vm.localListeners))
}
var staticRenderFns = []

export { render, staticRenderFns }